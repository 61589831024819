<template>
    <div class="c-page-frame u-scrollbar relative w-full overflow-y-auto">
        <div class="c-page-frame__content p-8">
            <slot />
        </div>
        <div class="c-page-frame__background u-gradient-border h-full w-full"></div>
    </div>
</template>

<!-- 
    add style tag with lang scss.
    Stack Background and Screen with grid method.
 -->
<style lang="scss"></style>
